import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TProfile, TSection13MotivationKeys} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function ProfileForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TProfile>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.profile);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    const onSubmit: SubmitHandler<TProfile> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {profile: data}}, {merge: true});
            dispatch(showNotification({message: 'Personal details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'profile', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Personal Details</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>First names</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter first names"
                    defaultValue={defaultValues?.firstNames}
                    isInvalid={!!errors.firstNames}
                    {...register("firstNames", {required: true})}
                />
                {errors.firstNames && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    defaultValue={defaultValues?.lastName}
                    isInvalid={!!errors.lastName}
                    {...register("lastName", {required: true})}
                />
                {errors.lastName && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                    defaultValue={defaultValues?.gender}
                    isInvalid={!!errors.gender}
                    { ...register("gender", { required: true }) }
                >
                    <option value="male">Male</option>
                    <option value={"female"}>Female</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>ID number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter ID number"
                    defaultValue={defaultValues?.idNumber}
                    isInvalid={!!errors.idNumber}
                    {...register("idNumber", {required: true, pattern: /^[0-9]{13}$/i})}
                />
                {errors.idNumber && <Form.Control.Feedback type="invalid">Enter a valid ID number</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter occupation"
                    defaultValue={defaultValues?.occupation}
                    isInvalid={!!errors.occupation}
                    {...register("occupation", {required: true})}
                />
                {errors.occupation && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Cell number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter cell number"
                    defaultValue={defaultValues?.cellNumber}
                    isInvalid={!!errors.cellNumber}
                    {...register("cellNumber", {required: true, pattern: /^[0-9]{10}$/i})}
                />
                {errors.cellNumber && <Form.Control.Feedback type="invalid">Enter a valid cell number (10 digits)</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter email address"
                    defaultValue={defaultValues?.emailAddress}
                    isInvalid={!!errors.emailAddress}
                    {...register("emailAddress", {required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i})}
                />
                {errors.emailAddress && <Form.Control.Feedback type="invalid">Enter a valid email address</Form.Control.Feedback>}
            </Form.Group>

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default ProfileForm;
