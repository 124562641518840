import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TChild, TFamily} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function FamilyForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TFamily>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.family);
    const [showSpouseDetails, setShowSpouseDetails] = useState<boolean>(false);
    const [showYearsMarried, setShowYearsMarried] = useState<boolean>(false);
    const [children, setChildren] = useState<TChild[]>([]);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    useEffect(() => {
        if (defaultValues) {
            setShowSpouseDetails(defaultValues.relationshipStatus === 'married' || defaultValues.relationshipStatus === 'engaged');
            setShowYearsMarried(defaultValues.relationshipStatus === 'married');
            setChildren(defaultValues.children || [])
        }
    } ,[defaultValues]);
    const onSubmit: SubmitHandler<TFamily> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        //TODO validate children

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {family: {...data, children}}}, {merge: true});
            dispatch(showNotification({message: 'Family details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'family', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Family</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Relationship Status</Form.Label>

                <Form.Select
                    defaultValue={defaultValues?.relationshipStatus}
                    isInvalid={!!errors.relationshipStatus}
                    {
                        ...register("relationshipStatus",
                        {required: true, onChange: (e) => {
                                setShowYearsMarried(e.target.value === 'married');
                                setShowSpouseDetails(e.target.value === 'married' || e.target.value === 'engaged');
                            }
                    })}
                >
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="engaged">Engaged</option>
                </Form.Select>
                {errors.relationshipStatus && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            {showSpouseDetails && (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Spouse First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Spouse First Name"
                            defaultValue={defaultValues?.spouseFirstName}
                            isInvalid={!!errors.spouseFirstName}
                            {...register("spouseFirstName", {required: true})}
                        />
                        {errors.spouseFirstName && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Spouse Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Spouse Last Name"
                            defaultValue={defaultValues?.spouseLastName}
                            isInvalid={!!errors.spouseLastName}
                            {...register("spouseLastName", {required: true})}
                        />
                        {errors.spouseLastName && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>
                </>
            )}

            {showYearsMarried && (
                <Form.Group className="mb-3">
                    <Form.Label>Years Married</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Years Married"
                        defaultValue={defaultValues?.yearsMarried}
                        isInvalid={!!errors.yearsMarried}
                        {...register("yearsMarried", {required: true, min: 0})}
                    />
                    {errors.yearsMarried && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                </Form.Group>
            )}

            <Form.Group className="mb-3">
                <Form.Label>Number of Children</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Number of Children"
                    defaultValue={defaultValues?.children?.length}
                    onChange={(e) => {
                        const num = parseInt(e.target.value);
                        if (isNaN(num) || num < 1) {
                            setChildren([]);
                        }
                        const newChildren = [...children];
                        if (num > children.length) {
                            for (let i = children.length; i < num; i++) {
                                newChildren.push({age: 0, gender: 'male'});
                            }
                        } else {
                            newChildren.splice(num, children.length - num);
                        }
                        console.log('newChildren: ' + newChildren.length);
                        setChildren(newChildren);
                    }}
                />
            </Form.Group>

            {children.map((child, index) => (
                <div key={index}>
                    <h5>Child {index + 1}</h5>
                    <Form.Group className="mb-3">
                        <Form.Label>Age</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Child Age"
                            value={child.age}
                            onChange={(e) => {
                                const num = parseInt(e.target.value);
                                if (isNaN(num) || num < -1) {
                                    return;
                                }

                                const newChildren = [...children.map(c => ({...c}))];
                                newChildren[index].age = num;
                                setChildren(newChildren);
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                            value={child.gender}
                            onChange={(e) => {
                                const newChildren = [...children.map(c => ({...c}))];
                                newChildren[index].gender = e.target.value as 'male' | 'female';
                                setChildren(newChildren);
                            }}
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            ))}

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default FamilyForm;
