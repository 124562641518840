export type TPricePoint = {
    price: number,
    title: string,
    description: string,
    featureList: string[],
    mostPopular: boolean
}

const generalReferralDiscount = 50;
const influencerReferralDiscount = 100;
const influencerPayout = 80;

const basePrice = 100 + 97;
const priorNoticePrice = 20;
const statsCost = 80;

const myCut = (basePrice + priorNoticePrice + statsCost - influencerReferralDiscount - influencerPayout);
export const pricePoints: TPricePoint[] = [
    // {
    //     price: basePrice,
    //     title: "Basic",
    //     description: "Just the basics",
    //     featureList: [
    //         "10+ pages of motivation content",
    //         "Customer support",
    //     ],
    //     mostPopular: false
    // },
    // {
    //     price: basePrice + priorNoticePrice,
    //     title: "Pro",
    //     description: "Get more for your money",
    //     featureList: [
    //         "10+ pages of motivation content",
    //         "Customer support",
    //         "Includes request for prior notice (requests SAPS notify you before any potential rejections)",
    //     ],
    //     mostPopular: false
    // },
    {
        price: basePrice + priorNoticePrice + statsCost,
        title: "All inclusive",
        description: "Everything you need in a motivation",
        featureList: [
            "+-10 pages of motivation content (length varies depending on details provided)",
            "Request for prior notice (requests SAPS notify you before any potential rejections)",
            "Attached crime stats for your specific area",
            "Money back guarantee if your application is rejected",
            "Customer support"
        ],
        mostPopular: false
    }
];
