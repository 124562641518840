
/**************START FIREBASE **************/
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics, Analytics, logEvent } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";
import { Functions, getFunctions } from 'firebase/functions';
import {AnalyticsCallOptions, EventParams} from "@firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyA1wJtFhyI4av2JDuX2rOii68AAid_hUgM",
    authDomain: "firearm-motivations.firebaseapp.com",
    projectId: "firearm-motivations",
    storageBucket: "firearm-motivations.appspot.com",
    messagingSenderId: "1073351962515",
    appId: "1:1073351962515:web:5689be4ef81ff1d3964266",
    measurementId: "G-7BR0E3N2BK"
  };

/**************END FIREBASE **************/

let app: FirebaseApp | null = null;
let analytics: Analytics | null = null;
let db: Firestore | null = null;
let functions: Functions | null = null;

const firebaseApp = (): FirebaseApp => {
    if (app) return app;

    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    //Init analytics
    firebaseAnalytics();

    return app;
}

const firebaseAnalytics = (): Analytics => {
    if (analytics) return analytics;

    analytics = getAnalytics(firebaseApp());

    return analytics;
}
// function logEvent(analyticsInstance: Analytics, eventName: 'add_payment_info', eventParams?: {
//     coupon?: EventParams['coupon'];
//     currency?: EventParams['currency'];
//     items?: EventParams['items'];
//     payment_type?: EventParams['payment_type'];
//     value?: EventParams['value'];
//     [key: string]: any;
// }, options?: AnalyticsCallOptions): void;


const logAnalyticsEvent = (eventName: string, eventParams?: {
    coupon?: EventParams['coupon'];
    currency?: EventParams['currency'];
    items?: EventParams['items'];
    payment_type?: EventParams['payment_type'];
    value?: EventParams['value'];
    // [key: string]
}, options?: AnalyticsCallOptions) => {
    if (process.env.NODE_ENV === 'development') {
        console.info(`Analytics event: ${eventName}`, eventParams, options);
        return;
    }

    logEvent(firebaseAnalytics(), eventName, eventParams, options);
}

const firebaseDB = (): Firestore => {
    if (db) return db;

    db = getFirestore(firebaseApp());
    return db;
}

const firebaseFunctions = (): Functions => {
    if (functions) return functions;

    functions = getFunctions(firebaseApp());
    return functions;
}

export { firebaseApp, firebaseDB, firebaseAnalytics, firebaseFunctions, logAnalyticsEvent };
