import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'

function Solution() {
    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Image style={{width: 'auto', height: '80%'}} src={"/images/logo-color.png"}/>
                </Col>

                <Col sm={12} md={12} lg={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <h3>The fastest and cheapest license motivations</h3>
                    <br/>
                    <p>
                        <b>Fast and Affordable:</b> Get your motivation in hours, not weeks. We use the latest AI technology to generate your motivation for you, saving human hours and therefore saving you time and money.
                    </p>

                    <p>
                        <b>Customised and Unique:</b> Using your profile and provided information, we'll generate a motivation that is unique to you. No more generic motivations that don't fit your needs.
                    </p>

                    <p>
                        <b>Easy To Use:</b> We'll ask you all the questions we need to know to help create your motivation. We'll email you the motivation and you can download it from our website as soon as it's done.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Solution;
