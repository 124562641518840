import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TPurposeOfUseS13, TResidence} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function PurposeOfUseS13Form() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TPurposeOfUseS13>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.purposeOfUseS13);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    const onSubmit: SubmitHandler<TPurposeOfUseS13> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {purposeOfUseS13: data}}, {merge: true});
            dispatch(showNotification({message: 'Purpose of use saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'purposeOfUseS13', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Purpose Of Use</h2>
            <p>Scenarios in which you would need a firearm for sell defence</p>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Activities/Hobbies</Form.Label>
                <Form.Check
                    type={'checkbox'}
                    id={`cycling`}
                    label={`Cycling`}
                    defaultChecked={defaultValues?.cycling}
                    {...register("cycling")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`hiking`}
                    label={`Hiking`}
                    defaultChecked={defaultValues?.hiking}
                    {...register("hiking")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`running`}
                    label={`Running`}
                    defaultChecked={defaultValues?.running}
                    {...register("running")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`fishing`}
                    label={`Fishing`}
                    defaultChecked={defaultValues?.fishing}
                    {...register("fishing")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`motorcycling`}
                    label={`Motorcycling`}
                    defaultChecked={defaultValues?.motorcycling}
                    {...register("motorcycling")}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Other reasons</Form.Label>
                <Form.Check
                    type={'checkbox'}
                    id={`ownACar`}
                    label={`Own a car`}
                    defaultChecked={defaultValues?.ownACar}
                    {...register("ownACar")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`experienceLoadShedding`}
                    label={`Experience load shedding`}
                    defaultChecked={defaultValues?.experienceLoadShedding}
                    {...register("experienceLoadShedding")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`walkToWork`}
                    label={`Walk to work`}
                    defaultChecked={defaultValues?.walkToWork}

                    {...register("walkToWork")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`workNightShifts`}
                    label={`Work night shifts`}
                    defaultChecked={defaultValues?.workNightShifts}
                    {...register("workNightShifts")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`workHighRiskArea`}
                    label={`Work in a high risk area`}
                    defaultChecked={defaultValues?.workHighRiskArea}
                    {...register("workHighRiskArea")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`driveForWork`}
                    label={`Drive for work`}
                    defaultChecked={defaultValues?.driveForWork}
                    {...register("driveForWork")}
                />
            </Form.Group>
            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default PurposeOfUseS13Form;
