import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TFinalise} from 'firearm-motivation-types';
import {firebaseDB, logAnalyticsEvent} from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";

function FinaliseForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TFinalise>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.finalise);

    const onSubmit: SubmitHandler<TFinalise> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        if (!data.accepted) {
            dispatch(showNotification({message: 'Please check the box to confirm you understand.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {finalise: data}}, {merge: true});

            dispatch(showNotification({message: 'Motivation Finalised', type: 'success'}));
            logAnalyticsEvent('ads_conversion_PURCHASE_1')
            navigate('/app/dashboard?success=true');
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }

    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    if (defaultValues.accepted) {
        return (
            <div>
                <p>
                    Thank you for finalising your details. We will review it and send you an email with the motivation, crime stats for your area and request for prior notice within 24 hours provided there are no issues with details provided.
                </p>

                <p>
                    Please note that you will not be able to edit your motivation once it has been finalised. If you need to make changes, please contact us at <a href="mailto:info@motivations.co.za">info@motivations.co.za</a>.
                </p>
            </div>
        );
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Finalise Motivation</h2>
            <br/>

            <p>
                Please ensure that you have completed all the steps in the motivation process accurately before submitting your motivation.
            </p>

            <p>
                Once you have submitted your motivation, we will review it and send you an email with the motivation, crime stats for your area and request for prior notice within 24 hours provided there are no issues with details provided.
            </p>

            <p style={{
                fontSize: '0.8rem',
            }}>
                I am submitting a motivation for a firearm license and that I am responsible for the accuracy of the information provided.
            </p>

            <Form.Group className="mb-3">
                {/*<Form.Label>I understand that I am submitting a motivation for a firearm license and that I am responsible for the accuracy of the information provided.</Form.Label>*/}
                <Form.Check
                    type={'checkbox'}
                    id={`accepted`}
                    label={'I understand'}
                    {...register("accepted")}
                    required
                />
            </Form.Group>

            <Button type={'submit'} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
        </Form>
    );
}

export default FinaliseForm;
