import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'

function Testimonials() {
    const Review = ({name, description, image}: {name: string, description: string, image: string}) => {
        return (
            <Col sm={12} md={4} lg={4} style={{display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'center'}}>
                <p style={{textAlign: 'center', padding: 20}}>"{description}"</p>

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Image roundedCircle src={image} style={{maxHeight: 60, width: 'auto', marginRight: 15}}/>

                    <div>
                        <h3 style={{marginTop: 20, textAlign: 'center'}}>{name}</h3>
                        <span>⭐⭐⭐⭐⭐</span>
                    </div>
                </div>

            </Col>
        );
    }

    return (
        <Container>
            <h2 style={{textAlign: 'center', marginBottom: 40}}>What our early users have to say</h2>
            <Row>
                <Review
                    name={"Christi"}
                    description={"Highly recommended! This site creates South African firearm motivations fast. Easy process, user-friendly interface, and helpful customer support. Satisfaction guaranteed!"}
                    image={"/images/reviews/christi.jpg"}
                />
                <Review
                    name={"Travis"}
                    description={"Streamlined process, user-friendly interface, and really cheap! Thumbs up!"}
                    image={"/images/reviews/travis.jpg"}
                />
                <Review
                    name={"Giselle"}
                    description={"Exceptional! This site offers fast South African firearm motivations. Smooth process, intuitive interface, and superb customer support. Highly recommend!"}
                    image={"/images/reviews/giselle.jpg"}
                />
            </Row>
        </Container>
    );
}

export default Testimonials;
