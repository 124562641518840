
import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { TPricePoint, pricePoints } from '../../util/pricing';
import SignupButton from "../../common/SignupButton";

const formatAsCurrency = (price: number): string => {
    return `R${price.toFixed(2)}`;
}

function Pricing() {
    const Price = ({price, title, description, featureList, mostPopular}: TPricePoint) => {
        return (
            <Col sm={12} md={12} lg={6}>
                <Card style={{ margin: 20 }}>
                    {/*<Card.Img variant="top" src="holder.js/100px180?text=Image cap" />*/}
                    <Card.Body>
                        <Card.Title>
                            <div style={{textAlign: 'center'}}>
                                <h2>{title}</h2>
                                {mostPopular ? <Badge bg={'success'}>Most Popular</Badge> : <span/>}
                            </div>
                        </Card.Title>
                        <Card.Text style={{textAlign: 'center'}}>
                            {description}
                        </Card.Text>

                        <h4 style={{textAlign: 'center'}}>
                            {formatAsCurrency(price)}
                        </h4>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        {featureList.map((feature) => {
                            return (
                                <ListGroup.Item key={feature}>{feature}</ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                    <Card.Body style={{textAlign: 'center'}}>
                        <SignupButton size={'lg'}>Get Started</SignupButton>
                    </Card.Body>
                </Card>
            </Col>
        );
    }

    return (
        <Container id={"pricing"}>
            <Row  className={'justify-content-center'}>
                {pricePoints.map((pricePoint) => {
                    return (
                        <Price key={pricePoint.title} {...pricePoint} />
                    );
                })}
            </Row>
        </Container>
    );
}

export default Pricing;
