import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { firebaseDB, firebaseFunctions } from '../../util/firebase';
import {userId, userEmail, showNotification} from '../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {useNavigate, useParams} from "react-router-dom";
import { httpsCallable } from 'firebase/functions';
import Alert from 'react-bootstrap/Alert'

type TContactForm = {
    name: string;
    emailAddress: string;
    message: string;
}

function ContactForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
        clearErrors
    } = useForm<TContactForm>()
    const dispatch = useAppDispatch();
    const currentUserEmail = useAppSelector(userEmail);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.profile);
    const [submitted, setSubmitted] = React.useState(false);

    const onSubmit: SubmitHandler<TContactForm> = async (data) => {
        try {
            const addMessage = httpsCallable(firebaseFunctions(), 'submitContactForm');
            await addMessage(data);
            
            reset();
            clearErrors();
            setSubmitted(true);

            dispatch(showNotification({message: 'Contact email sent!', type: 'success'}));
        } catch (error) {
            console.error("Error submitting form:");
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {submitted ? 
                <Alert variant={'success'}>
                    <Alert.Heading>Thank you!</Alert.Heading>
                    <p>
                        Your message has been sent. We will get back to you as soon as possible.
                    </p>
                </Alert> :
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            defaultValue={defaultValues?.firstNames}
                            isInvalid={!!errors.name}
                            {...register("name", {required: true})}
                        />
                        {errors.name && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter email address"
                            defaultValue={!submitted ? currentUserEmail : undefined}
                            isInvalid={!!errors.emailAddress}
                            {...register("emailAddress", {required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i})}
                        />
                        {errors.emailAddress && <Form.Control.Feedback type="invalid">Enter a valid email address</Form.Control.Feedback>}
                    </Form.Group>
                    

                    <Form.Group className="mb-3">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter message"
                            isInvalid={!!errors.message}
                            {...register("message", {required: true})}
                            style={{minHeight: 140}}
                        />
                        {errors.message && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>

                    <Button type={'submit'} disabled={isSubmitting}>{isSubmitting ? 'Sending...' : 'Send'}</Button>
                </>
            }
        </Form>
    );
}

export default ContactForm;
