import React, {useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate} from "react-router-dom";
import {firebaseDB} from "../../util/firebase";
import {section13MotivationDetailsKeys, TMotivationEntry} from "firearm-motivation-types";
import {addDoc, collection, onSnapshot, query, where, Timestamp} from "firebase/firestore";
import {showNotification, userId} from "../../store/userSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {type} from "os";

function MotivationCard({title, description, percentageComplete, type, link, onClick}: {title: string, description: string, percentageComplete?: number, link?: string, type: 'new' | 'coming-soon' | 'in-progress' |  'complete' , onClick?: (() => void)}) {
    let button: JSX.Element | null = null;
    if (type == 'new') {
        button = <Button variant="primary" onClick={onClick}>Create New</Button>;
    } else if (type == 'complete') {
        button = <Button variant="success" disabled>Done</Button>;
    } else if (type == 'coming-soon') {
        button = <Button variant="dark" disabled>Coming Soon</Button>;
    } else if (type == 'in-progress') {
        button = <Button style={{width: '100%'}} variant="primary">{percentageComplete == 100 ? 'Finalise' : 'Continue'} {percentageComplete??0 > 0 ? `(${percentageComplete}%)` : ''}</Button>;
    }

    return (
        <Col xs={6} sm={6} md={6} lg={4} style={{marginBottom: 20}}>
            <Card style={{minHeight: 160}}>
                <Card.Body style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                    <div style={{marginBottom: 10}}>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>
                            {description}
                        </Card.Text>
                    </div>

                    {link ?
                        <Link to={link} style={{width: '100%'}}>
                            {button}
                        </Link>
                    : button}
                </Card.Body>
            </Card>
        </Col>
    );
}

function DashboardPage() {
    const currentUserId = useAppSelector(userId);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [motivations, setMotivations] = React.useState<{[key: string]: TMotivationEntry}>({});
    const [isCreatingNew, setIsCreatingNew] = React.useState(false);

    useEffect(() => {
        if (!currentUserId) {
            return;
        }

        const db = firebaseDB();
        //on collection 'motivations' where userId = currentUserId
        const ref = collection(db, "motivations");

        const q = query(ref, where("userId", "==", currentUserId));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let motivations: {[key: string]: TMotivationEntry} = {};
            querySnapshot.forEach((doc) => {
                motivations[doc.id] = doc.data() as TMotivationEntry;
                console.log(doc.id, " => ", doc.data());
            });
            setMotivations(motivations);
        });

        return () => unsubscribe();
    }, [currentUserId]);


    const getLink = (id: string): string => {
        //TODO maybe get the first incomplete form
        return `/app/motivation/${id}`;
    }

    //TODO look for existing applications and show them here

    return (
        <Row>
            {isCreatingNew && <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', zIndex: 1000}}/>}
            <MotivationCard title={'New Section 13 Motivation'} description={'Self defence firearm motivation.'} type={'new'} onClick={
                async () => {
                    if (!currentUserId) {
                        dispatch(showNotification({message: 'Please login to continue', type: 'error'}));
                        return;
                    }

                    setIsCreatingNew(true);

                    const data: TMotivationEntry = {
                        status: 'incomplete',
                        userId: currentUserId,
                        startedAt: Timestamp.now(),
                        section: 'Section 13',
                        params: {},
                    };

                    console.log(data);

                    try {
                        const ref = await addDoc(collection(firebaseDB(), "motivations"), data);

                        dispatch(showNotification({message: 'New Section 13 Motivation created', type: 'success'}));

                        navigate(getLink(ref.id));
                    } catch (error) {
                        console.error(error);
                        dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
                        return;
                    }
                }
            }/>
            <MotivationCard title={'New Section 15 Motivation'} description={'Occasional sport shooting or hunting motivation'} type={'coming-soon'}/>
            <MotivationCard title={'New Section 16 Motivation'} description={'Dedicated sport shooting or hunting motivation'} type={'coming-soon'}/>

            {Object.keys(motivations).map((id) => {
                const {startedAt, section, status, completedAt, params} = motivations[id];
                let title = `${section} Motivation`;

                let description = 'Started at ' + startedAt.toDate().toLocaleString();
                if (completedAt) {
                    description = 'Completed at ' + completedAt.toDate().toLocaleString();
                }

                //TODO show application state

                let completedCount = 0;
                section13MotivationDetailsKeys.forEach((key) => {
                    if (params[key]) {
                        completedCount += 1;
                    }
                });

                const keysExcludingFinalise = section13MotivationDetailsKeys.filter((key) => key !== 'finalise');
                const percentageComplete = Math.round((completedCount / (keysExcludingFinalise.length)) * 100);

                let link = getLink(id);
                if (percentageComplete == 100) {
                    link += '/finalise';
                } else {
                    link += '/firearm';
                }

                //TODO check processing status when automated
                if (params['finalise']?.accepted) {
                    return (
                        <MotivationCard key={startedAt.nanoseconds} title={title} description={description} type={'complete'} link={link}/>
                    );
                }

                return (
                    <MotivationCard key={startedAt.nanoseconds} title={title} description={description} percentageComplete={percentageComplete} type={'in-progress'} link={link}/>
                );
            })}
        </Row>
    );
}

export default DashboardPage;
