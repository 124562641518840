import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TExistingFirearm, allUnitStandards, licenceSections} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function ExistingFirearmsForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TExistingFirearm[]>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => {
        const data = state.user.motivationDetails?.existingFirearms;
        if (data && !data.list) {
            //Else always undefined and loader never disappears
            return []
        }

        return data?.list;
    });
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    const [numberOfFirearms, setNumberOfFirearms] = useState<number>(defaultValues?.length || 0);
    const [deletedIndexes, setDeletedIndexes] = useState<number[]>([]);

    useEffect(() => {
        if ((defaultValues?.length ?? 0) > 0) {
            console.log("Setting..."  + defaultValues)
            setNumberOfFirearms(defaultValues?.length ?? 0);
        }
    }, [defaultValues]);

    const onSubmit: SubmitHandler<TExistingFirearm[]> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        const list = Object.keys(data)
            .map((index) => {
                return data[parseInt(index)];
            }).filter((item, index) => {
                return !deletedIndexes.includes(index);
            });

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {existingFirearms: {list}}}, {merge: true});
            dispatch(showNotification({message: 'Existing firearms saved', type: 'success'}));

            navigate(getNextIncompleteStepLink(motivationSections, 'existingFirearms', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Existing firearms</h2>
            <br/>

            {Array.from(Array(numberOfFirearms).keys()).map((index) => {
                if (deletedIndexes.includes(index)) {
                    return null;
                }

                return (
                    <div key={index} style={{marginBottom: 60}}>
                        <div style={{flex: 1}}>
                            <Form.Group className="mb-3">
                                <Form.Label>Type</Form.Label>

                                <Form.Select
                                    defaultValue={defaultValues[index]?.type}
                                    isInvalid={!!errors[index]?.type}
                                    {...register(`${index}.type`, {required: true})}
                                >
                                    <option value="handgun">Handgun</option>
                                    <option value="rifle">Rifle</option>
                                    <option value="shotgun">Shotgun</option>
                                    <option value="self loading rifle">Self loading rifle</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Make</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Make"
                                    defaultValue={defaultValues[index]?.make}
                                    isInvalid={!!errors[index]?.make}
                                    {...register(`${index}.make`, {required: true})}
                                />
                                {errors[index]?.make && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Model"
                                    defaultValue={defaultValues[index]?.model}
                                    isInvalid={!!errors[index]?.model}
                                    {...register(`${index}.model`, {required: true})}
                                />
                                {errors[index]?.model && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Serial number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Serial number"
                                    defaultValue={defaultValues[index]?.serialNumber}
                                    isInvalid={!!errors[index]?.serialNumber}
                                    {...register(`${index}.serialNumber`, {required: true})}
                                />
                                {errors[index]?.serialNumber && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Calibre</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Calibre"
                                    defaultValue={defaultValues[index]?.calibre}
                                    isInvalid={!!errors[index]?.calibre}
                                    {...register(`${index}.calibre`, {required: true})}
                                />
                                {errors[index]?.calibre && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Licence Type</Form.Label>

                                <Form.Select
                                    defaultValue={defaultValues[index]?.section}
                                    isInvalid={!!errors[index]?.section}
                                    {...register(`${index}.section`, {required: true})}
                                >
                                    {licenceSections.map((section) => {
                                        return <option key={section} value={section}>{section}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>License number (at the back of card)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="License number"
                                    defaultValue={defaultValues[index]?.licenseNumber}
                                    isInvalid={!!errors[index]?.licenseNumber}
                                    {...register(`${index}.licenseNumber`, {required: true})}
                                />
                                {errors[index]?.licenseNumber && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Date issued</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Date issued"
                                    defaultValue={defaultValues[index]?.dateIssued}
                                    isInvalid={!!errors[index]?.dateIssued}
                                    {...register(`${index}.dateIssued`, {required: true})}
                                />
                                {errors[index]?.dateIssued && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                            </Form.Group>

                        </div>

                        <Button variant={'outline-danger'} onClick={() => setDeletedIndexes(prevState => [...prevState, index])}>
                            Delete
                        </Button>
                    </div>
                );
            })}

            <Button variant={'outline-primary'} onClick={() => setNumberOfFirearms(numberOfFirearms + 1)}>Add {numberOfFirearms > 0 ? 'another' : 'new'} firearm</Button>
            <br/><br/>

            {numberOfFirearms > 0 ?
                <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button> :
                <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Skipping...' : 'Skip'}</Button>
            }
        </Form>
    );
}

export default ExistingFirearmsForm;
