import React from 'react';

import Button from "react-bootstrap/Button";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {isAuthenticated, showSignupModal} from "../store/userSlice";
import {Link} from "react-router-dom";


function SignupButton(props: {size?: 'sm' | 'lg', children: string}) {
    const {size, children} = props;
    const dispatch = useAppDispatch()
    const isAuth = useAppSelector(isAuthenticated);
    const showSignup = () => dispatch(showSignupModal(true));

    if (isAuth) {
        return <Link to={"/app/dashboard"}><Button variant="primary" size={size ?? 'lg'}>{children}</Button></Link>;
    }

    return (
        <Button variant="primary" size={size ?? 'lg'} onClick={showSignup}>{children}</Button>
    );
}

export default SignupButton;
