import {MotivationSectionState} from "../store/userSlice";

export const getNextIncompleteStepLink = (steps: MotivationSectionState[], currentKey: string, motivationId?: string) => {
    let nextKey = '';
    let hitKey = false;

    steps.forEach(({key, completed}) => {
        if (key === currentKey) {
            hitKey = true;
            return;
        }

        if (hitKey && !completed && !nextKey) {
            nextKey = key;
        }
    });

    if (!nextKey) {
        nextKey = 'finalise'; //Nothing incomplete, go to finalise
    }

    return `/app/motivation/${motivationId??''}/${nextKey}`;
}
