
import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'

function Benefits() {
    const Benefit = ({title, description, image}: {title: string, description: string, image: string}) => {
        return (
            <Col sm={12} md={4} lg={4} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Image src={image} style={{maxHeight: 140, width: 'auto'}}/>
                <h3 style={{marginTop: 20}}>{title}</h3>
                <p style={{textAlign: 'center'}}>{description}</p>
            </Col>
        );
    }

    return (
        <Container>
            <Row>
                <Benefit
                    title={"Fast Results"}
                    description={"Get your motivation unbelievably fast so you can submit your application ASAP."}
                    image={"/images/fast-color.png"}
                />
                <Benefit
                    title={"24/7 availability"}
                    description={"Our motivations are created around the clock, improving accessibility and responsiveness."}
                    image={"/images/24-7-color.png"}
                />
                <Benefit
                    title={"Cheap"}
                    description={"Affordable so you don't have to try compile your own. More cost-effective than hiring a service."}
                    image={"/images/cheap-color.png"}
                />
            </Row>
        </Container>
    );
}

export default Benefits;
