import { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {userNotifications, clearNotification} from '../store/userSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks';

function Notification() {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(userNotifications);

    useEffect(() => {
        const timer = setInterval(() => {
            notifications.forEach(({id, timestamp}) => {
                if (Date.now() - timestamp! > 4000) {
                    dispatch(clearNotification(id!));
                }
            });

        }, 1000);
        return () => clearTimeout(timer);
    });

    return (
        <ToastContainer
            className="p-3"
            position={'top-end'}
            style={{ zIndex: 1 }}
        >
            {notifications.map(({id, type, message}) => {
                let bg = 'success';
                let title = 'Success';
                if (type === 'error') {
                    bg = 'danger';
                    title = 'Error';
                } else if (type === 'warning') {
                    bg = 'warning';
                    title = 'Warning';
                } else if (type === 'info') {
                    bg = 'info';
                    title = 'Info';
                }

                //TODO animate in/out
                return (
                    <Toast key={id} bg={bg} animation onClose={() => dispatch(clearNotification(id!))}>
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2"
                                alt=""
                            />
                            <strong className="me-auto">{title}</strong>
                        </Toast.Header>
                        <Toast.Body>{message}</Toast.Body>
                    </Toast>
                )
            })}
        </ToastContainer>
      );
}

export default Notification;
