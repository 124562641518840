import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import {TSection13MotivationKeys, TSection13MotivationParams} from "firearm-motivation-types";

interface Notification {
    id?: string;
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
    timestamp?: number;
}

export interface MotivationSectionState {
    key: TSection13MotivationKeys;
    title: string;
    completed: boolean | null;
}

// Define a type for the slice state
export interface UserState {
    showAuth: boolean;
    authType: "login" | "signup";
    isAuth: boolean | null;
    userId?: string;
    email?: string;
    notifications: Notification[];
    motivationSectionState: MotivationSectionState[];
    motivationDetails: TSection13MotivationParams;
}

// Define the initial state using that type
const initialState: UserState = {
    showAuth: false,
    authType: "signup",
    isAuth: null,
    userId: undefined,
    notifications: [],
    motivationSectionState: [
        {
            key: 'firearm', //Application specific
            title: 'Firearm',
            completed: null,
        },
        {
            key: 'profile',
            title: 'Personal details',
            completed: null,
        },
        {
            key: 'address',
            title: 'Address',
            completed: null,
        },
        {
            key: 'residence',
            title: 'Residence details',
            completed: null,
        },
        {
            key: 'employer',
            title: 'Employer details',
            completed: null,
        },
        {
            key: 'family',
            title: 'Family',
            completed: null,
        },
        {
            key: 'experience',
            title: 'Firearm experience',
            completed: null,
        },
        {
            key: 'competencies',
            title: 'Competency',
            completed: null,
        },
        {
            key: 'proficiencies',
            title: 'Proficiency',
            completed: null,
        },
        {
            key: 'purposeOfUseS13',
            title: 'Purpose of use',
            completed: null,
        },
        {
            key: 'existingFirearms',
            title: 'Existing firearms',
            completed: null,
        },
        {
            key: 'safe',
            title: 'Safe details',
            completed: null,
        },
        {
            key: 'finalise',
            title: 'Finalise',
            completed: null,
        }
    ],
    motivationDetails: {}
} as UserState;

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        showAuth: state => {
            state.showAuth = true;
        },
        showSignupModal: (state, action: PayloadAction<boolean>) => {
            state.authType = 'signup';
            state.showAuth = action.payload
        },
        showLoginModal: (state, action: PayloadAction<boolean>) => {
            state.authType = 'login';
            state.showAuth = action.payload
        },
        setUserId: (state, action: PayloadAction<string | undefined>) => {
            const userId = action.payload;
            state.isAuth = !!userId;
            state.userId = userId;
        },
        setUserEmail: (state, action: PayloadAction<string | undefined>) => {
            state.email = action.payload;
        },
        showNotification: (state, action: PayloadAction<Notification>) => {
            const notification = action.payload;
            notification.timestamp = Date.now();
            notification.id = Math.random().toString(10) + Date.now();
            state.notifications.push(notification);
            if (notification.type === 'error') {
                console.error("ERROR: " + notification.message);
            } else {
                console.log("NOTIFICATION: " + notification.message);
            }
        },
        clearNotification: (state, action: PayloadAction<string>) => {
            const notificationId = action.payload;
            state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
        },
        setMotivationSectionState: (state, action: PayloadAction<{ key: string, completed: boolean }>) => {
            const { key, completed } = action.payload;
            const section = state.motivationSectionState.find(section => section.key === key);
            if (section) {
                section.completed = completed;
            }
        },
        updateMotivationDetails: (state, action: PayloadAction<{ key: TSection13MotivationKeys, data: any }>) => {
            const { key, data } = action.payload;
            state.motivationDetails[key] = data ?? {};
        },
    }
})

export const {
    showSignupModal,
    showLoginModal,
    setUserId,
    showNotification,
    clearNotification,
    setMotivationSectionState,
    updateMotivationDetails,
    setUserEmail
} = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const isAuthenticated = (state: RootState) => state.user.isAuth;
export const userId = (state: RootState) => state.user.userId;
export const userEmail = (state: RootState) => state.user.email;
export const userNotifications = (state: RootState) => state.user.notifications;
export const motivationSectionState = (state: RootState) => state.user.motivationSectionState;

export const motivationDetails = (state: RootState) => state.user.motivationDetails;
export default userSlice.reducer
