import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TResidence} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function ResidenceForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TResidence>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.residence);
    const [showSecurityCompany, setShowSecurityCompany] = useState<boolean>(false);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    useEffect(() => {
        if (defaultValues?.securityCompany) {
            setShowSecurityCompany(true);
        }
    } ,[defaultValues]);
    const onSubmit: SubmitHandler<TResidence> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {residence: data}}, {merge: true});
            dispatch(showNotification({message: 'Residence details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'residence', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Residence Details</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Property Type</Form.Label>

                <Form.Select
                    defaultValue={defaultValues?.type}
                    isInvalid={!!errors.type}
                    {...register("type", {required: true})}
                >
                    <option value="farm">Farm</option>
                    <option value="townhouse">Townhouse</option>
                    <option value="house">House</option>
                    <option value="security complex">Security Complex</option>
                    <option value="security estate">Security Estate</option>
                    <option value="flat">Flat</option>
                </Form.Select>
                {errors.type && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Property Enclosed With</Form.Label>

                <Form.Select
                    defaultValue={defaultValues?.propertyEnclosedWith}
                    isInvalid={!!errors.type}
                    {...register("propertyEnclosedWith", {required: true})}
                >
                    <option>Select Option</option>
                    <option value="nothing">Nothing</option>
                    <option value="wall">Wall</option>
                    <option value="fence">Fence</option>
                    <option value="electric fence">Electric Fence</option>
                    <option value="palisade fence">Palisade Fence</option>
                    <option value="wire fence">Wire Fence</option>
                    <option value="other">Other</option>
                </Form.Select>
                {errors.propertyEnclosedWith && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Gate</Form.Label>

                <Form.Select
                    defaultValue={defaultValues?.gate}
                    isInvalid={!!errors.type}
                    {...register("gate", {required: true})}
                >
                    <option value="none">No gate</option>
                    <option value="manual">Manual Gate</option>
                    <option value="electric">Electric Gate</option>
                    <option value="security guard">Security Guard</option>
                </Form.Select>
                {errors.gate && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Property Security</Form.Label>
                <Form.Check
                    type={'checkbox'}
                    id={`securityGate`}
                    label={`Security Gate`}
                    defaultChecked={defaultValues?.securityGate}
                    {...register("securityGate")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`alarm`}
                    label={`Alarm`}
                    defaultChecked={defaultValues?.alarm}
                    {...register("alarm")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`securityCompany`}
                    label={`Security Company`}
                    defaultChecked={defaultValues?.securityCompany}
                    {...register("securityCompany", {onChange: (e) => setShowSecurityCompany(e.target.checked)})}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`burglarBars`}
                    label={`Burglar Bars`}
                    defaultChecked={defaultValues?.burglarBars}
                    {...register("burglarBars")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`securityBars`}
                    label={`Security Bars On Windows`}
                    defaultChecked={defaultValues?.securityBars}
                    {...register("securityBars")}
                />
            </Form.Group>

            {showSecurityCompany && (
                <Form.Group className="mb-3">
                    <Form.Label>Security Company Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Security Company Name"
                        defaultValue={defaultValues?.securityCompanyName}
                        isInvalid={!!errors.securityCompanyName}
                        {...register("securityCompanyName", {required: true})}
                    />
                    {errors.securityCompanyName && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                </Form.Group>
            )}

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default ResidenceForm;
