import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TProfiency, allUnitStandards} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function ProficiencyForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TProfiency[]>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => {
        const data = state.user.motivationDetails?.proficiencies;
        if (data && !data.list) {
            //Else always undefined and loader never disappears
            return []
        }

        return data?.list;
    });
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    const [selectedUnitStandards, setSelectedUnitStandards] = useState<{[us: string]: boolean}>(
        allUnitStandards.reduce((acc, us) => {
            return {
                ...acc,
                [us]: false,
            }
        }, {})
    );

    useEffect(() => {
        if (defaultValues) {
            defaultValues.forEach((proficiency) => {
                setSelectedUnitStandards((prevState) => {
                    return {
                        ...prevState,
                        [proficiency.standard]: true,
                    };
                });
            });
        }
    }, [defaultValues]);

    const onSubmit: SubmitHandler<TProfiency[]> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        const list = Object.keys(data).map((index) => {
            return data[parseInt(index)];
        });

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {proficiencies: {list}}}, {merge: true});
            dispatch(showNotification({message: 'Proficiency details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'proficiencies', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Proficiency</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Training Certificates</Form.Label>

                {Object.keys(selectedUnitStandards).map((us, index) => {
                    return (
                        <Form.Check
                            key={index}
                            type={'checkbox'}
                            id={us}
                            label={us}
                            checked={selectedUnitStandards[us]}
                            onChange={(e) => {
                                setSelectedUnitStandards({...selectedUnitStandards, [us]: e.target.checked})
                            }}
                        />
                    )
                })}
            </Form.Group>

            {allUnitStandards.map((us, index) => {
                return (
                    <div key={index}>
                        {selectedUnitStandards[us] && (
                            <div style={{marginTop: 60}}>
                                <h5>{us}</h5>

                                {/*Hidden field, so it's still posted and object is valid*/}
                                <Form.Control
                                    type="text"
                                    placeholder="Firearm type"
                                    defaultValue={us}
                                    isInvalid={!!errors[index]?.standard}
                                    hidden
                                    {...register(`${index}.standard`, {required: true})}
                                />

                                <Form.Group className="mb-3">
                                    <Form.Label>Training provider</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Training provider"
                                        defaultValue={defaultValues.find((proficiency) => proficiency.standard === us)?.trainingProvider}
                                        isInvalid={!!errors[index]?.trainingProvider}
                                        {...register(`${index}.trainingProvider`, {required: true})}
                                    />
                                    {errors[index]?.trainingProvider && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        defaultValue={defaultValues.find((proficiency) => proficiency.standard === us)?.city}
                                        isInvalid={!!errors[index]?.city}
                                        {...register(`${index}.city`, {required: true})}
                                    />
                                    {errors[index]?.trainingProvider && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Training date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        defaultValue={defaultValues.find((proficiency) => proficiency.standard === us)?.date}
                                        isInvalid={!!errors[index]?.date}
                                        {...register(`${index}.date`, {required: true})}
                                    />
                                    {errors[index]?.date && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                                </Form.Group>
                            </div>
                        )}
                    </div>
                );
            })}

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default ProficiencyForm;
