import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image'
import Tooltip from "react-bootstrap/Tooltip";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { pricePoints } from '../../util/pricing';
import SignupButton from "../../common/SignupButton";

const Node = ({tooltip, tooltipPlacement, ...props}: any) => {
    return (
        <OverlayTrigger
            placement={tooltipPlacement}
            delay={{ show: 100, hide: 200 }}
            overlay={
                <Tooltip>
                    {tooltip}
                </Tooltip>
            }
        >
        <Button {...props}/>
        </OverlayTrigger>
    );
}

const Arrow = () => {
    return <div style={{margin: 5}} >⬇️</div>;
}

const Flow = ({size}: {size: 'sm' | 'md'}) => {
    let tooltipPlacement = size === 'sm' ? 'top' : 'left';
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {/* <Node
                size={size}
                variant="primary"
                tooltipPlacement={tooltipPlacement}
                tooltip={"Send a note to your assistant via email, WhatsApp or Telegram. Text or voice notes!"}
            >
                📲&nbsp;Email/Text/Voice&nbsp;note
            </Node>
            <Arrow/> */}
            <Node
                size={size}
                variant="success"
                tooltipPlacement={tooltipPlacement}
                tooltip={"Enter all your details into our easy to use form. We'll ask you all the questions we need to know to help create your motivation."}
            >
                🧑‍💻&nbsp;Complete&nbsp;Profile
            </Node>
            <Arrow/>
            <Node
                size={size}
                variant="success"
                tooltipPlacement={tooltipPlacement}
                tooltip={"We'll generate a personalised motivation for you based on your profile."}
            >
                📄&nbsp;Generate&nbsp;Motivation
            </Node>
            <Arrow/>
            <Node
                size={size}
                variant="success"
                tooltipPlacement={tooltipPlacement}
                tooltip={"A complete motivation can be done in just a few clicks. We'll email you the motivation and you can download it from our website."}
            >
                📤&nbsp;Download&nbsp;Motivation
            </Node>
        </div>
    );
}

function Hero() {
    return (
        <Container>
            <Row className={'justify-content-center'}>
                <Col sm={12} md={12} lg={5}>
                    <h1 className={'text-primary'}><b>Firearm License Motivations in&nbsp;a&nbsp;day</b></h1>
                    <h4 style={{marginTop: 20, marginBottom: 40}}>
                        {"Applying for a firearm license? Create an affordable custom motivation online now."}
                    </h4>

                    <SignupButton>GET STARTED</SignupButton>

                    <div style={{marginTop: 40}}>
                        <p>✔️&nbsp;100% unique and tailored to you</p>
                        <p>✔️&nbsp;Money back guarantee if license is declined</p>
                        <p>✔️&nbsp;Built for South Africans 🇿🇦</p>
                        <p>✔️&nbsp;Just <b>R{pricePoints[0].price}</b> per motivation</p>
                        {/*<p>✔️&nbsp;Brainstorming/idea creation</p>*/}
                    </div>
                </Col>

                <Col sm={12} md={12} lg={7} style={{ position: 'relative' }}>
                    <Image src={'/images/happy-customer.jpg'} fluid rounded/>

                    {/*DESKTOP*/}
                    <div className={"d-none d-md-block"} style={{ position: 'absolute', top: '35%', left: '75%', transform: 'translate(-50%, -50%)' }}>
                        <Flow size={'md'}/>
                    </div>

                    {/*MOBILE*/}
                    <div className={"d-block d-md-none"} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Flow size={'sm'}/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Hero;
