import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TAddress} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function AddressForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TAddress>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.address);

    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    const onSubmit: SubmitHandler<TAddress> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {address: data}}, {merge: true});

            dispatch(showNotification({message: 'Home address saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'address', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Home Address</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Street number and name / Farm</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter street number and name / farm"
                    defaultValue={defaultValues?.street}
                    isInvalid={!!errors.street}
                    {...register("street", {required: true})}
                />
                {errors.street && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Suburb</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter suburb"
                    defaultValue={defaultValues?.suburb}
                    isInvalid={!!errors.suburb}
                    {...register("suburb", {required: true})}
                />
                {errors.suburb && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter city"
                    defaultValue={defaultValues?.city}
                    isInvalid={!!errors.city}
                    {...register("city", {required: true})}
                />
                {errors.city && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Province</Form.Label>

                <Form.Select
                    defaultValue={defaultValues?.province}
                    isInvalid={!!errors.province}
                    {...register("province", {required: true, pattern: /^[a-zA-Z]+$/i})}
                >
                    <option>Select Province</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="North West">North West</option>
                    <option value="Free State">Free State</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="Western Cape">Western Cape</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                    <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                </Form.Select>
                {errors.province && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Postal code</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter postal code"
                    defaultValue={defaultValues?.postalCode}
                    isInvalid={!!errors.postalCode}
                    {...register("postalCode", {required: true, pattern: /^[0-9]{4}$/i})}
                />
                {errors.postalCode && <Form.Control.Feedback type="invalid">Invalid postal code</Form.Control.Feedback>}
            </Form.Group>

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default AddressForm;
