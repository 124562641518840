import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Alert from 'react-bootstrap/Alert';

import {useAppDispatch, useAppSelector} from "../store/hooks";
import {showSignupModal, showLoginModal} from "../store/userSlice";
import {useNavigate} from "react-router-dom";

function AuthModal() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    // const show = useAppSelector(showAuthModal);
    const showAuth = useAppSelector(state => state.user.showAuth);
    const authType = useAppSelector(state => state.user.authType);

    const handleClose = () => {
        dispatch(showSignupModal(false));
        dispatch(showLoginModal(false));
    };

    const toggleSignIn = () => {
        if (authType === 'login') {
            dispatch(showLoginModal(false));

            setTimeout(() => {
                dispatch(showSignupModal(true));
            }, 400);
        } else {
            dispatch(showSignupModal(false));

            setTimeout(() => {
            dispatch(showLoginModal(true));
            }, 400);
        }
    }

    const onAuth = () => {
        setError('');
        setIsSubmitting(true);

        const auth = getAuth();
        const method = authType === 'login' ? signInWithEmailAndPassword : createUserWithEmailAndPassword;

        if (authType === 'signup' && password !== password2) {
            setError("Passwords do not match");
            setIsSubmitting(false);
            return;
        }

        method(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                handleClose();
                //Navigate to /dashboard
                navigate('/app/dashboard');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(error);

                setError(errorMessage.replaceAll('Firebase: ', ''));
            }).finally(() => {
                setIsSubmitting(false);
            });
    }

    const googleAuth = () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        setIsSubmitting(true);

        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                // The signed-in user info.
                const user = result.user;

                handleClose();
                //Navigate to /dashboard
                navigate('/app');
                // IdP data available using getAdditionalUserInfo(result)
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...

                setError(errorMessage.replaceAll('Firebase: ', ''));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <Modal show={showAuth} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{authType === 'login' ? "Welcome back" : "Sign Up"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                    </Form.Group>

                    {authType === 'signup' && (
                        <Form.Group className="mb-3" controlId="formGroupPassword2">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" value={password2} onChange={e => setPassword2(e.target.value)} />
                        </Form.Group>
                    )}

                    {error && (
                        <Alert variant="danger">
                            {error}
                        </Alert>
                    )}
                    <br/>
                    <Form.Group className="d-grid gap-2">
                        <Button disabled={isSubmitting} variant="primary" onClick={onAuth}>
                            {isSubmitting ? "Authenticating..." : authType === 'login' ? "Login" : "Sign Up"}
                        </Button>
                        <Button variant="link" onClick={toggleSignIn}>
                            {authType === 'login' ? "Don't have an account yet?" : "Already have an account?"}
                        </Button>
                    </Form.Group>
                </Form>

                {/*<div className="d-grid gap-2" style={{marginTop: 30}}>*/}
                {/*    <Button variant="secondary" disabled={isSubmitting} onClick={googleAuth}>*/}
                {/*        <FaGoogle style={{marginRight: 10}}/>*/}
                {/*        Continue with Google*/}
                {/*    </Button>*/}
                    {/*<Button variant="secondary">*/}
                    {/*    <FaFacebook style={{marginRight: 10}}/>*/}
                    {/*    Continue with Facebook*/}
                    {/*</Button>*/}
                {/*</div>*/}
            </Modal.Body>
        </Modal>
    );
}

export default AuthModal;
