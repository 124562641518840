import React, {useEffect, useState} from 'react';
import FormProgress from '../../common/FormProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import {Link, Outlet, useLocation, useParams} from 'react-router-dom';
import {
    motivationSectionState,
    setMotivationSectionState,
    updateMotivationDetails,
    userId
} from "../../store/userSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {firebaseDB} from "../../util/firebase";
import {doc, onSnapshot} from "firebase/firestore";
import Spinner from "react-bootstrap/Spinner";

const NavLink = ({ children, to, active, ...props }: { children: string, to: string, active: boolean }) => (
    <Link
        className={`nav-link text-decoration-none ${active ? 'text-primary' : 'text-black'}` }
        to={to} {...props}
        style={active ? {fontWeight: 'bold'} : {}}
        >
        {children}
    </Link>
);

function FormsPage() {
    const location = useLocation();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const dispatch = useAppDispatch();

    const [activeKey, setActiveKey] = useState('');
    const [formReady, setFormReady] = useState(false);

    //Show which form is active
    useEffect(() => {
        motivationSections.forEach((item) => {
            if (location.pathname.includes(item.key)) {
                setActiveKey(item.key);
            }
        });

    }, [location, motivationSections]);

    //Subscribe to all db values
    useEffect(() => {
        if (!currentUserId) {
            return;
        }

        const db = firebaseDB();

        if (!motivationId) {
            return
        }

        //Subscribe to motivation specific values in that collection
        const unsubscribe = onSnapshot(doc(db, 'motivations', motivationId), (doc) => {
            const params = doc.data()?.params ?? {};

            motivationSections.forEach(({key}) => {
                dispatch(setMotivationSectionState({ key, completed: !!(params && params[key]) }));
                dispatch(updateMotivationDetails({key, data: params ? params[key] : {}}))
            });

            setFormReady(true);
        });

        return () => unsubscribe();
    }, [currentUserId, motivationId, dispatch, motivationSections]);

    return (
        <Row style={{paddingBottom: 40}}>
            <h1>Section 13 Motivation</h1>
            <br/>
            <Col xs={12} sm={6} md={5}>
                <Card style={{marginBottom: 20}}>
                    <Card.Body>
                        <div style={{marginTop: 10, marginBottom: 20}}>
                            <FormProgress />
                        </div>

                        <Nav defaultActiveKey="/home" className="flex-column">
                            {motivationSections.map((item, index) => {
                                let emoji = '👀';
                                if (item.completed) {
                                    emoji = '✅';
                                } else if (item.completed === false) {
                                    emoji = '📝';
                                }

                                return (
                                    <NavLink key={index} to={`/app/motivation/${motivationId}/${item.key}`} active={(item.key === activeKey)}>
                                        {`${emoji} ${item.title}`}
                                    </NavLink>
                                );
                            })}
                        </Nav>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={6} md={7}>
                {formReady ? <Card>
                    <Card.Body>
                        <Outlet />
                    </Card.Body>
                </Card> :
                <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                    <Spinner animation="border" variant="primary" />
                </div>}
            </Col>
        </Row>
    );
}

export default FormsPage;
