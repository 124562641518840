import React from 'react';
import Spinner from "react-bootstrap/Spinner";

function FormLoader() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            margin: 40,
        }}>
            <Spinner animation="border" variant="primary" />
        </div>
    );
}

export default FormLoader;
