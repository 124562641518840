import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TCompetency, TFirearmTypes} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function CompetenciesForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TCompetency[]>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => {
        const data = state.user.motivationDetails?.competencies;
        if (data && !data.list) {
            //Else always undefined and loader never disappears
            return []
        }

        return data?.list;
    });

    const [selectedFirearmTypes, setSelectedFirearmTypes] = useState<{[key in TFirearmTypes]: boolean}>({
        'handgun': false,
        'shotgun': false,
        'rifle': false,
        'self loading rifle': false,
    });

    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    useEffect(() => {
        if (defaultValues) {
            defaultValues.forEach((competency) => {
                setSelectedFirearmTypes((prevState) => {
                    return {
                        ...prevState,
                        [competency.type]: true,
                    };
                });
            });
        }
    }, [defaultValues]);

    const firearmTypes: TFirearmTypes[] = ['handgun', 'shotgun', 'rifle', 'self loading rifle'];

    const onSubmit: SubmitHandler<TCompetency[]> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        let list = Object.keys(data).map((index) => {
            return data[parseInt(index)];
        });

        //Filter out if selectedFirearmTypes is false
        list = list.filter((competency) => {
            return selectedFirearmTypes[competency.type];
        });

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {competencies: {list}}}, {merge: true});
            dispatch(showNotification({message: 'Competency details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'competencies', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Competency</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Competencies held</Form.Label>

                {firearmTypes.map((type, index) => {
                    return (
                        <Form.Check
                            key={index}
                            type={'checkbox'}
                            id={type}
                            label={type.charAt(0).toUpperCase() + type.slice(1)}
                            checked={selectedFirearmTypes[type]}
                            onChange={(e) => {
                                setSelectedFirearmTypes({...selectedFirearmTypes, [type]: e.target.checked})
                            }}
                        />
                    )
                })}
            </Form.Group>

            {firearmTypes.map((type, index) => {
                return (
                    <div key={index}>
                        {selectedFirearmTypes[type] && (
                            <div style={{marginTop: 60}}>
                                <h4>{type.charAt(0).toUpperCase() + type.slice(1)}</h4>

                                {/*Hidden field, so it's still posted and object is valid*/}
                                <Form.Control
                                    type="text"
                                    placeholder="Firearm type"
                                    defaultValue={type}
                                    isInvalid={!!errors[index]?.type}
                                    hidden
                                    {...register(`${index}.type`, {required: true})}
                                />

                                <Form.Group className="mb-3">
                                    <Form.Label>Certificate number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter competency number"
                                        defaultValue={defaultValues.find((competency) => competency.type === type)?.certificateNumber}
                                        isInvalid={!!errors[index]?.certificateNumber}
                                        {...register(`${index}.certificateNumber`, {required: true})}
                                    />
                                    {errors[index]?.certificateNumber && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Competency issue date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        defaultValue={defaultValues.find((competency) => competency.type === type)?.issueDate}
                                        isInvalid={!!errors[index]?.issueDate}
                                        {...register(`${index}.issueDate`, {required: true})}
                                    />
                                    {errors[index]?.issueDate && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Competency renewal date<br/><span style={{fontSize: 12}}><i>Leave blank if not yet renewed</i></span></Form.Label>
                                    <Form.Control
                                        type="date"
                                        defaultValue={defaultValues.find((competency) => competency.type === type)?.renewalDate}
                                        isInvalid={!!errors[index]?.renewalDate}
                                        {...register(`${index}.renewalDate`, {required: false})}
                                    />
                                    {errors[index]?.renewalDate && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                                </Form.Group>
                            </div>
                        )}
                    </div>
                );
            })}

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default CompetenciesForm;
