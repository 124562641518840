import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TFirearm} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function FirearmForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TFirearm>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.firearm);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    const onSubmit: SubmitHandler<TFirearm> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {firearm: data}}, {merge: true});
            dispatch(showNotification({message: 'Firearm details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'firearm', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Firearm Details</h2>
            <p>The firearm for which you are applying for a license.</p>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Make</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Make"
                    defaultValue={defaultValues?.make}
                    isInvalid={!!errors.make}
                    {...register("make", {required: true})}
                />
                {errors.make && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Model</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Model"
                    defaultValue={defaultValues?.model}
                    isInvalid={!!errors.model}
                    {...register("model", {required: true})}
                />
                {errors.model && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>

                {/*TODO block firearms from section 13*/}
                <Form.Select
                    defaultValue={defaultValues?.type}
                    isInvalid={!!errors.type}
                    {...register("type", {required: true})}
                >
                    <option value="handgun">Handgun</option>
                    <option value="rifle">Rifle</option>
                    <option value="shotgun">Shotgun</option>
                    <option value="self loading rifle">Self loading rifle</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Calibre</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Calibre"
                    defaultValue={defaultValues?.calibre}
                    isInvalid={!!errors.calibre}
                    {...register("calibre", {required: true})}
                />
                {errors.calibre && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Serial Number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Serial Number"
                    defaultValue={defaultValues?.serialNumber}
                    isInvalid={!!errors.serialNumber}
                    {...register("serialNumber", {required: true})}
                />
                {errors.serialNumber && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            {/*<Form.Group className="mb-3">*/}
            {/*    <Form.Label>Postal code</Form.Label>*/}
            {/*    <Form.Control*/}
            {/*        type="text"*/}
            {/*        placeholder="Enter postal code"*/}
            {/*        defaultValue={defaultValues?.postalCode}*/}
            {/*        isInvalid={!!errors.postalCode}*/}
            {/*        {...register("postalCode", {required: true, pattern: /^[0-9]{4}$/i})}*/}
            {/*    />*/}
            {/*    {errors.postalCode && <Form.Control.Feedback type="invalid">Invalid postal code</Form.Control.Feedback>}*/}
            {/*</Form.Group>*/}

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default FirearmForm;
