import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";

import {useAppDispatch, useAppSelector} from '../store/hooks';
import {showSignupModal, showLoginModal, isAuthenticated} from '../store/userSlice'
import {getAuth, signOut} from "firebase/auth";

function AppNavBar({showLandingLinks }: { showLandingLinks: boolean }) {
    const dispatch = useAppDispatch()
    const showLogin = () => dispatch(showLoginModal(true));
    const showSignup = () => dispatch(showSignupModal(true));
    const isAuth = useAppSelector(isAuthenticated);

    const onLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("Signed out");
        }).catch((error) => {
            alert(error)
        });
    }

    const NavLink = ({ children, to, ...props }: { children: string, to: string }) => (
        <Link className="nav-link text-decoration-none text-black" to={to} {...props}>
            {children}
        </Link>
    );

    const DropDownItem = ({ children, to, ...props }: { children: string, to: string}) => (
        <Link className={"dropdown-item"} to={to} {...props}>
            {children}
        </Link>
    );

    const RightNav = () => {
        if (isAuth === true) {
            return (
                <>
                    <NavLink to="/app/dashboard">Dashboard</NavLink>
                    <NavDropdown title="Account" id="basic-nav-dropdown">
                        {/*<NavDropdown.Item>Profile</NavDropdown.Item>*/}
                        {/*<NavDropdown.Item>*/}
                        {/*    Settings*/}
                        {/*</NavDropdown.Item>*/}

                        <DropDownItem to="/support">Support</DropDownItem>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={onLogout}>
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </>
            );
        }

        if (isAuth === false) {
            return (
                <>
                    <Nav.Link onClick={showSignup}>Sign Up</Nav.Link>
                    <Nav.Link onClick={showLogin}>Login</Nav.Link>
                </>
            );
        }

        return <span/>
    }

    return (
        <Navbar bg="light" variant="light" expand="md">
            <Container>
                <img
                    alt={"Motivations"}
                    src={"/images/logo-color.png"}
                    style={{
                        width: 'auto',
                        height: 40,
                        objectFit: 'cover'
                    }}
                />
                <Navbar.Brand>
                    <Link className="text-decoration-none" to="/">Fast Motivations</Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink to="/">Home</NavLink>

                        {showLandingLinks ?
                        <>
                            <Nav.Link href={"/#features"}>Features</Nav.Link>
                            <Nav.Link href={"/#pricing"}>Pricing</Nav.Link>
                        </>
                        : null
                    }
                    </Nav>

                    <Nav className="justify-content-end">
                        <RightNav/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNavBar;
