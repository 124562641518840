import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TFirearmExperience} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function ExperienceForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TFirearmExperience>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.experience);
    const [showShootingClub, setShowShootingClub] = useState<boolean>(false);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    useEffect(() => {
        if (defaultValues?.shootingClubMembership) {
            setShowShootingClub(true);
        }
    } ,[defaultValues]);
    const onSubmit: SubmitHandler<TFirearmExperience> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {experience: data}}, {merge: true});
            dispatch(showNotification({message: 'Firearm experience saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'experience', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Firearm Experience</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Previous use of firearms</Form.Label>
                <Form.Check
                    type={'checkbox'}
                    id={`range`}
                    label={`Shooting Range Visits`}
                    defaultChecked={defaultValues?.range}
                    {...register("range")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`military`}
                    label={`Military`}
                    defaultChecked={defaultValues?.military}
                    {...register("military")}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`hunting`}
                    label={`Hunting`}
                    defaultChecked={defaultValues?.hunting}
                    {...register("hunting" )}
                />
                <Form.Check
                    type={'checkbox'}
                    id={`sportShooting`}
                    label={`Sports shooting`}
                    defaultChecked={defaultValues?.sportShooting}
                    {...register("sportShooting")}
                />
                {/*<Form.Check*/}
                {/*    type={'checkbox'}*/}
                {/*    id={`securityBars`}*/}
                {/*    label={`Security Bars On Windows`}*/}
                {/*    defaultChecked={defaultValues?.securityBars}*/}
                {/*    {...register("securityBars")}*/}
                {/*/>*/}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Do you belong to a shooting club?</Form.Label>
                <Form.Check
                    type={'checkbox'}
                    id={`shootingClubMembership`}
                    label={`Yes`}
                    defaultChecked={defaultValues?.shootingClubMembership}
                    {...register("shootingClubMembership", {onChange: (e) => setShowShootingClub(e.target.checked)})}
                />
            </Form.Group>

            {showShootingClub && (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Shooting club name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Shooting club name"
                            defaultValue={defaultValues?.shootingClubName}
                            isInvalid={!!errors.shootingClubName}
                            {...register("shootingClubName", {required: true})}
                        />
                        {errors.shootingClubName && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Shooting club membership number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Membership number"
                            defaultValue={defaultValues?.shootingClubMembershipNumber}
                            isInvalid={!!errors.shootingClubMembershipNumber}
                            {...register("shootingClubMembershipNumber", {required: true})}
                        />
                        {errors.shootingClubMembershipNumber && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Member since</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Member since"
                            defaultValue={defaultValues?.shootingClubMemberSince}
                            isInvalid={!!errors.shootingClubMemberSince}
                            {...register("shootingClubMemberSince", {required: true})}
                        />
                        {errors.shootingClubMemberSince && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                    </Form.Group>

                </>
            )}

            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default ExperienceForm;
