import React, {useEffect} from 'react';

import Hero from "./Hero";
import Solution from "./Solution";
import Benefits from "./Benefits";
import Testimonials from "./Testimonials";
import Features from "./Features";
import Faq from "./Faq";
import Pricing from "./Pricing";
import {showLoginModal} from "../../store/userSlice";
import {useAppDispatch} from "../../store/hooks";
import SupportPage from '../support/SupportPage';
import Footer from "./Footer";

const spacer = <div style={{height: 140}}/>;
function HomePage() {
    const dispatch = useAppDispatch();
    const showLogin = () => dispatch(showLoginModal(true));

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const showAuth = urlParams.get('showAuth');
        if (showAuth) {
            showLogin();
            window.history.replaceState({}, document.title, "/");
        }
    } , []);

    return (
        <div id={"home"}>
            <Hero/>

            {spacer}

            <div className={"bg-light"} style={{paddingTop: 40, paddingBottom: 40}}>
                <Solution/>
            </div>

            {spacer}

            <Benefits/>

            {spacer}

            <div className={"bg-light"} style={{paddingTop: 40, paddingBottom: 40}}>
                <Testimonials/>
            </div>

            {spacer}

            <Features/>

            {spacer}

            <div className={"bg-light"} style={{paddingTop: 40, paddingBottom: 40}}>
                <Faq/>
            </div>

            {spacer}

            <Pricing/>

            {spacer}

            <SupportPage/>
            <Footer/>
        </div>
    );
}

export default HomePage;
