import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useAppSelector} from "../store/hooks";
import {motivationSectionState} from "../store/userSlice";
import {section13MotivationDetailsKeys} from "firearm-motivation-types";

function FormProgress() {
    const motivationSections = useAppSelector(motivationSectionState);

    const total = motivationSections.length - 1;
    const now = Math.round((motivationSections.filter((item) => item.completed).length / total * 100));

    return (
        <div>
            <ProgressBar now={now} label={`${now}%`} />
        </div>
    );
}

export default FormProgress;
