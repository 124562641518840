import React, {useEffect, useState} from 'react';
import { useForm, SubmitHandler } from "react-hook-form"
import { doc, setDoc } from "firebase/firestore";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {TSafe} from 'firearm-motivation-types';
import { firebaseDB } from '../../../util/firebase';
import {userId, showNotification, motivationSectionState} from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import FormLoader from "../../../common/FormLoader";
import {useNavigate, useParams} from "react-router-dom";
import {getNextIncompleteStepLink} from "../../../util/helpers";

function SafeForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<TSafe>()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const motivationId = params.id;
    const motivationSections = useAppSelector(motivationSectionState);
    const currentUserId = useAppSelector(userId);
    const defaultValues = useAppSelector((state) => state.user.motivationDetails?.safe);
    const [showSafeDescription, setShowSafeDescription] = useState<boolean>(false);
    const [showSafeFitted, setShowSafeFitted] = useState<boolean>(false);
    const disableForm = useAppSelector((state) => !!state.user.motivationDetails?.finalise?.accepted);

    useEffect(() => {
        if (defaultValues?.type) {
            setShowSafeDescription(defaultValues.type === 'other');
            setShowSafeFitted(defaultValues.type !== 'walk in gun room/vault' && defaultValues.type !== 'other')
        }
    } ,[defaultValues]);
    const onSubmit: SubmitHandler<TSafe> = async (data) => {
        if (!currentUserId || !motivationId) {
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
            return;
        }

        try {
            await setDoc(doc(firebaseDB(), `motivations`, motivationId), {params: {safe: data}}, {merge: true});
            dispatch(showNotification({message: 'Safe details saved', type: 'success'}));
            navigate(getNextIncompleteStepLink(motivationSections, 'safe', motivationId));
        } catch (error) {
            console.error(error);
            dispatch(showNotification({message: 'Sorry, something went wrong. Please try again.', type: 'error'}));
        }
    }

    //Loading the form before the default values are loaded will cause form to think values aren't set
    if (!defaultValues) {
        return <FormLoader />
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Safe Details</h2>
            <br/>

            <Form.Group className="mb-3">
                <Form.Label>Safe type</Form.Label>

                <Form.Select
                    defaultValue={defaultValues?.type}
                    isInvalid={!!errors.type}
                    {
                        ...register("type", {
                            required: true,
                            onChange: (e) => {
                                setShowSafeDescription(e.target.value === 'other');
                                setShowSafeFitted(e.target.value !== 'walk in gun room/vault' && e.target.value !== 'other');
                            }
                        })
                    }
                >
                    <option value="handgun">Handgun</option>
                    <option value="rifle">Rifle</option>
                    <option value="walk in gun room/vault">Walk in gun room/vault</option>
                    <option value="other">Other</option>
                </Form.Select>
                {errors.type && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
            </Form.Group>

            {showSafeDescription && (
                <Form.Group className="mb-3">
                    <Form.Label>Safe description</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Safe description"
                        defaultValue={defaultValues?.otherDescription}
                        isInvalid={!!errors.otherDescription}
                        {...register("otherDescription", {required: true})}
                    />
                    {errors.otherDescription && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                </Form.Group>
            )}

            {showSafeFitted && (
                <Form.Group className="mb-3">
                    <Form.Label>Fitted</Form.Label>

                    <Form.Select
                        defaultValue={defaultValues?.fitted}
                        isInvalid={!!errors.fitted}
                        {
                            ...register("fitted", {
                                required: true
                            })
                        }
                    >
                        <option value="wall">Wall</option>
                        <option value="floor">Floor</option>
                        <option value="both wall and floor">Both wall and floor</option>
                    </Form.Select>
                    {errors.type && <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>}
                </Form.Group>
            )}


            <Button type={'submit'} disabled={isSubmitting || disableForm}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        </Form>
    );
}

export default SafeForm;
