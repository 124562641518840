import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ContactForm from './ContactForm';
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'

const spacer = <div style={{height: 140}}/>;

function SupportPage() {
    return (
        <Container>
            <Row className={'justify-content-center'}>
                <h1>Contact Us</h1>
                <p>
                    Please use the form below to contact us for support or any other queries.
                </p>

                <Col sm={12} md={6} lg={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <ContactForm />
                </Col>

                <Col sm={12} md={12} lg={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Image style={{width: 'auto', height: 400}} src={"/images/logo-color.png"}/>
                </Col>

                {spacer}
            </Row>
        </Container>
    );
}

export default SupportPage;
