
import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const email = "info@motivations.co.za";
const cell = "+27 827 399 295";
const whatsapp = `https://wa.me/${cell.replaceAll(' ', '').replaceAll('+', '')}`;

function Footer() {
    return (
        <Container id={"footer"} style={{paddingBottom: 40}}>
            <Row>
                <Col sm={12} md={6} lg={6} >
                    <p>
                        Adapted Hub (Pty) Ltd (trading as <b>Motivations.co.za</b>)
                    </p>

                    <p>
                        <b>Email: </b>
                        <a
                            style={{textDecoration:'none', color: 'inherit'}}
                            href={`mailto:${email}`}
                        >
                            {email}
                        </a>
                    </p>

                    <p>
                        <b>WhatsApp: </b>
                        <a
                            style={{textDecoration:'none', color: 'inherit'}}
                            href={whatsapp}
                        >
                            {cell}
                        </a>
                    </p>


                </Col>

                {/*<Col sm={12} md={6} lg={6}>*/}
                {/*    <h2 style={{textAlign: 'end'}}>Motivations.co.za</h2>*/}
                {/*</Col>*/}
            </Row>
        </Container>
    );
}

export default Footer;
