
import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Features() {
    const Feature = ({title, children}: {title: string, children: string}) => {
        return (
            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>✅&nbsp;&nbsp;</span>
                <span><b>{title}</b> - {children}</span>
            </p>
        );
    }


    // "Personal details",
    // "Firearm experience",
    // "Currrent competency status",
    // "Security and safe storage facilities",
    // "Firearm specifics",
    // "Calibre",
    // "The act (Section 13)",
    // "SA crime stats", //Country, province, city, area specific examples. Rape if woman.
    // "Conclusion",
    // "Request for prior notice" //separate doc

    return (
        <Container id={"features"}>
            <h2 style={{textAlign: 'center', marginBottom: 40}}>Motivation Content</h2>
            <Row  className={'justify-content-center'}>
                <Col sm={12} md={6} lg={4} >
                    <Feature title={"Personal Details"}>
                        A brief summary and introduction of the applicant.
                    </Feature>
                    <Feature title={"Firearm Experience"}>
                        Outline of your firearm experience.
                    </Feature>
                    <Feature title={"Current Competency"}>
                        Your current competency status.
                    </Feature>
                    <Feature title={"Firearm Specifics"}>
                        The specifics of the firearm you wish to acquire and why.
                    </Feature>
                    <Feature title={"Calibre"}>
                        The calibre of the firearm you wish to acquire and why.
                    </Feature>
                    <Feature title={"Security and Safe Storage Facilities"}>
                        Your security and safe storage facilities.
                    </Feature>
                </Col>

                <Col sm={12} md={6} lg={4}>
                    <Feature title={"The Act (Section 13)"}>
                        The act and section 13/15/16 and how it related to you.
                    </Feature>
                    <Feature title={"South African Crime"}>
                        Basic South African crime statistics and how this can affect you.
                    </Feature>
                    <Feature title={"Conclusion"}>
                        A conclusion of your motivation, summarising your reasons for wanting to own a firearm.
                    </Feature>
                    <Feature title={"Request for Prior Notice"}>
                        A request for prior notice of the outcome of your application.
                    </Feature>
                    <Feature title={"Attached crime stats"}>
                        Attached latest crime stats for your area
                    </Feature>
                </Col>
            </Row>
        </Container>
    );
}

export default Features;
