import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';
import {pricePoints} from "../../util/pricing";

//TODO answer any objections you might have
const faq = [
    {
        question: "How does it work?",
        answer: "We'll ask you a few questions about youself, your situation and firearm usage. Then use those details to compile a custom motivation document you can use to apply for your firearm license."
    },
    {
        question: "What section license can it create?",
        answer: "Currently only section 13 (self defence) but section 15 and 16 will be ready soon!"
    },
    {
        question: "How much does it cost?",
        answer: `Flat once off rate of R${pricePoints[0].price} per motivation.`
    },
    {
        question: "Can I get a refund?",
        answer: "We offer full refunds if your application is declined due to our motivation document. Please provide us with the reason for the decline and we'll refund you immediately. Or if you would prefer, we can rework the motivation document for you at no extra cost."
    },
    {
        question: "How do I get started?",
        answer: "Click GET STARTED or sign up in the top right corner."
    },
    {
        question: "How do I cancel?",
        answer: "There is no need to cancel. You only pay once off per motivation document."
    },
    {
        question: "How do I contact you?",
        answer: "You can contact using our contact form below."
    }
];

function Faq() {
    const Item = ({eventKey, question, answer}: {eventKey: string, question: string, answer: string}) => {
        return (
            <Accordion.Item eventKey={eventKey}>
                <Accordion.Header>{question}</Accordion.Header>
                <Accordion.Body>
                    {answer}
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    return (
        <Container>
            <h2 style={{textAlign: 'center', marginBottom: 40}}>Frequently Asked Questions</h2>
            <Row className={'justify-content-center'}>
                <Col sm={12} md={10} lg={8}>
                    <Accordion defaultActiveKey="0">
                        {faq.map((item, index) => {
                            return (
                                <Item
                                    key={index}
                                    eventKey={index.toString()}
                                    question={item.question}
                                    answer={item.answer}
                                />
                            );
                        })}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default Faq;
