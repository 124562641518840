import React, {useEffect, useState} from 'react';
import './styles/_root.scss';
import HomePage from "./screens/home/HomePage";
import AppNavBar from "./common/NavBar";
import AuthModal from "./auth/AuthModal";
import { getAuth } from "firebase/auth";
import {
    Routes,
    Route,
    Outlet,
} from "react-router-dom";

import {setUserEmail, setUserId} from "./store/userSlice";
import {firebaseApp} from "./util/firebase";
import {useAppDispatch} from "./store/hooks";
import RequireAuth from "./auth/RequireAuth";
import SupportPage from "./screens/support/SupportPage";
import DashboardPage from './screens/app/DashboardPage';
import { Container } from 'react-bootstrap';

import FormsPage from './screens/app/FormsPage';
import ProfileForm from './screens/app/forms/ProfileForm';
import Notification from './common/Notification';
import AddressForm from "./screens/app/forms/AddressForm";
import ResidenceForm from "./screens/app/forms/ResidenceForm";
import EmployerForm from "./screens/app/forms/EmployerForm";
import FamilyForm from "./screens/app/forms/FamilyForm";
import ExperienceForm from "./screens/app/forms/ExperienceForm";
import SafeForm from "./screens/app/forms/SafeForm";
import FirearmForm from "./screens/app/forms/FirearmForm";
import CompetenciesForm from "./screens/app/forms/CompetencyForm";
import ProficiencyForm from "./screens/app/forms/ProficiencyForm";
import ExistingFirearmsForm from "./screens/app/forms/ExistingFirearmsForm";
import PurposeOfUseS13Form from "./screens/app/forms/PurposeOfUseS13Form";
import FinaliseForm from "./screens/app/forms/FinaliseForm";

const app = firebaseApp() //Keep this here to initialize firebase
const auth = getAuth();

function LandingLayout() {
    return (
      <div>
          <AuthModal/>
          <AppNavBar showLandingLinks/>
          <div style={{marginTop: 40}}>
              <Outlet/>
          </div>
      </div>
    );
}

function AppLayout() {
    return (
        <div>
            <AppNavBar showLandingLinks={false}/>
            <Container style={{paddingTop: 20}}>
                <Outlet/>
            </Container>
        </div>
    );
}

function App() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            // console.info(user);
            //TODO set other user info
            dispatch(setUserId(user?.uid));
            if (user?.email) {
                dispatch(setUserEmail(user?.email));
            }
        });

        return () => {
            unsubscribe();
        };
    });

    return (
        <div>
            <Notification/>
            <Routes>
            <Route element={<LandingLayout/>}>
                <Route path="/" element={<HomePage />} />
                <Route path="/support" element={<SupportPage />} />
            </Route>
            <Route path="/app" element={<AppLayout/>}>
                <Route path="dashboard" element={<DashboardPage/>} />
                <Route path="motivation/:id" element={<FormsPage/>}>
                    <Route path="firearm" element={<FirearmForm/>} />
                    <Route path="profile" element={<ProfileForm/>} />
                    <Route path="address" element={<AddressForm/>} />
                    <Route path="residence" element={<ResidenceForm />} />
                    <Route path="employer" element={<EmployerForm />} />
                    <Route path="family" element={<FamilyForm />} />
                    <Route path="experience" element={<ExperienceForm />} />
                    <Route path="competencies" element={<CompetenciesForm />} />
                    <Route path="proficiencies" element={<ProficiencyForm />} />
                    <Route path="existingFirearms" element={<ExistingFirearmsForm />} />
                    <Route path="safe" element={<SafeForm />} />
                    <Route path="purposeOfUseS13" element={<PurposeOfUseS13Form/>} />
                    <Route path="finalise" element={<FinaliseForm/>} />
                </Route>
            </Route>
        </Routes>
        </div>
    );
}

export default App;
